<template>
  <section id="container" class="full-width">
    <!--header start-->
    <header class="header black-bg">
      <div class="navbar-header">
        <!--logo start-->
        <a href="/order" class="logo text-white"
          ><img src="/img/CreoNordicLogohvit.png" class="logoimg" /> CreoNordic
        </a>

        <div class="top-nav">
          <User-Login-Drop-Down></User-Login-Drop-Down>
        </div>
      </div>
    </header>
    <!--header end-->
    <!--sidebar start-->

    <section id="main-content">
      <section class="wrapper">
        <!-- page start-->
        <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
        <div v-if="isLoading" class="text-center">
          <p>Creating user...</p>
          <b-spinner label="Loading..."></b-spinner>
        </div>

        <b-card v-else header="Register new user" class="mt-4">
          <b-form @submit="onSubmit">
            <b-row class="my-1 mt-3">
              <b-col sm="2">
                <label>Email *</label>
              </b-col>
              <b-col sm="10">
                <b-input
                  readonly
                  name="email"
                  id="email"
                  type="text"
                  v-model="user.email"
                ></b-input>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col>
                <label>First name *</label>
              </b-col>
              <b-col sm="10">
                <b-input
                  name="First name"
                  placeholder="Enter first name"
                  v-model="user.firstName"
                  required="required"
                ></b-input>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col>
                <label>Last name *</label>
              </b-col>
              <b-col sm="10">
                <b-input
                  name="Last name"
                  placeholder="Enter last name"
                  v-model="user.lastName"
                  required="required"
                ></b-input>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col>
                <label>Company name</label>
              </b-col>
              <b-col sm="10">
                <b-input
                  name="Company"
                  placeholder="Enter your company name"
                  v-model="user.company"
                ></b-input>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col>
                <label>Password *</label>
              </b-col>
              <b-col sm="10">
                <b-input
                  name="Password"
                  type="password"
                  placeholder="Enter password"
                  required="required"
                  v-model="user.password"
                ></b-input>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col>
                <label>Confirm password *</label>
              </b-col>
              <b-col sm="10">
                <b-input
                  name="confirm"
                  type="password"
                  placeholder="Confirm password"
                  v-model="confirmPassword"
                  required="required"
                ></b-input>
              </b-col>
            </b-row>

            <b-button type="submit" class="mt-5" variant="success"
              >Create</b-button
            >
            <Back-Button class="mt-5 ml-3"></Back-Button>
          </b-form>
        </b-card>
      </section>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import config from "@/store/config.js";
import BackButton from "@/components/Buttons/BackButton";

export default {
  name: "Register",
  components: {
    BackButton
  },
  data() {
    return {
      user: {
        email: "",
        firstName: "",
        lastName: "",
        company: "",
        username: "",
        password: ""
      },
      sendt: false,
      confirmPassword: "",
      isLoading: false,
      error: ""
    };
  },
  created() {
    this.user.email = this.$route.query.email || "";
    this.user.username = this.$route.query.email || "";
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();
      this.isLoading = true;
      this.error = "";
      //validate input
      if (!this.user.email.length > 0 && !this.user.username.length > 0) {
        this.error = "The e-mail is missing. ";
      }
      if (!this.user.firstName.length > 0 || !this.user.lastName.length > 0) {
        this.error += "Please add the first and last name. ";
      }
      if (
        !this.user.password.length > 0 ||
        !this.confirmPassword.length > 0 ||
        this.user.password !== this.confirmPassword
      ) {
        this.error += " The passwords are not matching. ";
      }
      if (this.error) {
        this.isLoading = false;
        return;
      }

      let self = this;
      //registering user
      await axios
        .post(`${config.AUTH}/register`, {
          ...this.user
        })
        .then(() => {
          self.$router.push("/login");
        })
        .catch(err => {
          self.error += err.message;
        });

      this.isLoading = false;
    }
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

.image-avatar {
  height: 25px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .logo div {
    display: none;
  }
  .logo:after {
    content: "Order";
  }
}
</style>
